.app {
  display: flex;
  flex-flow: column;
  -webkit-flex-flow: column;
  text-align: center;
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #cccccc;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #9a9a9a;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}