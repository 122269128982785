.header {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  background-color: rgba(2, 2, 2, .5);
  height: 50px;
  color: white;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header {
    height: 70px;
  }
}