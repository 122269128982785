.container {
  font-size: 20px;
  font-family: monospace;
  color: yellow;
  line-height: 30px;
  white-space: pre-wrap;
  text-align: left;
  height: 100%;
}

.cursor {
  border-left: 0.1em solid;
  animation: blink 1s steps(1) infinite;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 1025px) {
  .container {
    font-size: 20px;
    line-height: 35px;
  }
}
