.button {
  height: 35px;
  border: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #fff;
  background: transparent;
  cursor: pointer;
  font-family: 'ABeeZee';
  font-size: 14px;
  margin-right: 15px;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.button:focus {
  color: #999;
  border-color: #999 !important;
  outline : 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .button {
    height: 45px;
    font-size: 18px;
  }
}